import React, { useState } from 'react';
import './AlternateVenue.css'; // Import your CSS file for styling
import hotel1 from '../assets/images/althotel1.jpeg';
import hotel2 from '../assets/images/althotel2.jpg';
import hotel3 from '../assets/images/althotel3.jpeg';
import hotel4 from '../assets/images/althotel4.jpeg';
import hotel5 from '../assets/images/althotel5.jpg';
import hotel6 from '../assets/images/althotel6.jpg';
import { FaPhone, FaMapMarker } from 'react-icons/fa'; // Import icons for phone and email

const Venue = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="venue-container">
      <h1>Alternative Residential Package</h1>
      <div className="venue-details">
        <div className="venue-info">
          <p>
            <FaMapMarker /> Hotel Suhim Portico Heritage Sikkim, Helipad Road, <b>Next to Hotel Lemon Tree</b>, Near District Courts Near District
Administrative Centre, Gangtok 737101.
          </p>
          <p>
            <FaPhone /> +91 9933078807 - Mr. Emmanuel Tamang, Manager.
            
          </p>
          {/* <p>
            <FaEnvelope />{' '}
            <a href="mailto:hi.gt@lemontreehotels.com">hi.gt@lemontreehotels.com</a>
          </p> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d885.9931899664148!2d88.60788426955543!3d27.34532009852452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e6a530e487ffaf%3A0x97cb94aa19697c05!2sSuhim%20Portico%20Heritage%20Sikkim!5e0!3m2!1sen!2sin!4v1740590367255!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div className="venue-images">
          <h2>Hotel Suhim Portico Heritage Sikkim Images</h2>
          <div className="images-grid">
            <img
              src={hotel1}
              alt="Suhim Portico Heritage Sikkim 1"
              onClick={() => openImageModal(hotel1)}
            />
            <img
              src={hotel2}
              alt="Suhim Portico Heritage Sikkim 2"
              onClick={() => openImageModal(hotel2)}
            />
            <img
              src={hotel3}
              alt="Suhim Portico Heritage Sikkim 3"
              onClick={() => openImageModal(hotel3)}
            />
            <img
              src={hotel4}
              alt="Suhim Portico Heritage Sikkim 4"
              onClick={() => openImageModal(hotel4)}
            />
            <img
              src={hotel5}
              alt="Suhim Portico Heritage Sikkim 4"
              onClick={() => openImageModal(hotel5)}
            />
            <img
              src={hotel6}
              alt="Suhim Portico Heritage Sikkim 4"
              onClick={() => openImageModal(hotel6)}
            />
          </div>
        </div>
      </div>

      {selectedImage && (
        <div className="modal" onClick={closeImageModal}>
          <span className="close">&times;</span>
          <img className="modal-content" src={selectedImage} alt="Enlarged Venue" />
        </div>
      )}
      
    </div>

  );
};

export default Venue;
