import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './RegistrationForm.css';
import './QRScannerPopup.css';
import QRimg from '../assets/images/qr-code.png';
import { DNA } from 'react-loader-spinner';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    gender: '',
    email: '',
    mobile: '',
    packageName: '',
    transactionId: '',
    screenshot: null,
    membership: '',
    membershipId: '',
    // accompanyingMemberName: '',
    cost: 0,
    letterFromHOD: null,
  });

  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validations
    if (formData.age < 1 || formData.age > 99) {
      alert('Please enter a valid age between 1 and 99.');
      return;
    }
    if (!/^\d{10}$/.test(formData.mobile)) {
      alert('Please enter a valid 10-digit mobile number.');
      return;
    }

    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('age', formData.age);
    formDataToSend.append('gender', formData.gender);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('mobile', formData.mobile);
    formDataToSend.append('packageName', formData.packageName);
    formDataToSend.append('transactionId', formData.transactionId);
    formDataToSend.append('screenshot', formData.screenshot);
    formDataToSend.append('membership', formData.membership);
    // formDataToSend.append('accompanyingMemberName', formData.accompanyingMemberName || '');
    formDataToSend.append('cost', formData.cost);
    if (formData.membership === 'Member') {
      formDataToSend.append('membershipId', formData.membershipId);
    }
    if (formData.membership === 'PG') {
      formDataToSend.append('letterFromHOD', formData.letterFromHOD);
    }

    try {
      const response = await axios.post('https://www.neoscon2025.in/register', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 201) {
        setLoading(false);
        history('/registration-success');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      alert('There was an error sending the data.');
    }
  };

  const [isQRScannerOpen, setIsQRScannerOpen] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState('');

  const handlePaymentMethodChange = (e) => {
  setPaymentMethod(e.target.value);
};

  const [transactionIdMethod, setTransactionIdMethod] = useState('');

  const handleTransactionIdMethod = (e) => {
  setTransactionIdMethod(e.target.value);
};

  // Set the current time to IST
  const today = dayjs().tz('Asia/Kolkata');
  const earlyDate = dayjs('2025-02-15').tz('Asia/Kolkata');
  const lateDate = dayjs('2025-03-06').tz('Asia/Kolkata');

  // const earlyDate = dayjs('2024-09-01').tz('Asia/Kolkata');
  // const lateDate = dayjs('2024-09-20').tz('Asia/Kolkata');

useEffect(() => {
  calculateCost();
}, [formData.packageName, formData.membership]);

  const calculateCost = () => {
  const { packageName, membership } = formData;
  let cost = 0;

  

  

  // Determine if the date is early, late, or spot registration
  if (today.isBefore(earlyDate)) {
    // Early Registration Pricing
    if (membership === 'Member') {
      if (packageName === 'Residential Twin Sharing') {
        cost = 12000;
      } else if (packageName === 'Residential Family') {
        cost = 16000;
      } else if (packageName === 'Non-Residential') {
      cost = 3500;
      }
    } else if (membership === 'Non-member') {
      if (packageName === 'Residential Twin Sharing') {
        cost = 12000;
      } else if (packageName === 'Residential Family') {
      cost = 16000;
      } else if (packageName === 'Non-Residential') {
      cost = 4500;
      }
    } else if (membership === 'PG') {
      cost = 2500;
    }
  } else if (today.isBefore(lateDate)) {
    // Late Registration Pricing (1st Jan 2025 - 6th March 2025)
    if (membership === 'Member') {
      if (packageName === 'Residential Twin Sharing') {
        cost = 12000;
      } else if (packageName === 'Residential Family') {
        cost = 16000;
      } else if (packageName === 'Non-Residential') {
      cost = 4000;
      }
    } else if (membership === 'Non-member') {
      if (packageName === 'Residential Twin Sharing') {
        cost = 12000;
      } else if (packageName === 'Residential Family') {
        cost = 16000;
      } else if (packageName === 'Non-Residential') {
      cost = 5000;
      }
    } else if (membership === 'PG') {
      cost = 3000;
    }
  } else {
    // Spot Registration Pricing (After 6th March 2025)
    if (membership === 'Member') {
      cost = 4500;
    } else if (membership === 'Non-member') {
      cost = 5500;
    } else if (membership === 'PG') {
      cost = 3500;
    }
  }

  // // Add cost for accompanying member
  // if (accompanyingMemberName) {
  //   if (today.isBefore(earlyDate)) {
  //     cost += 1500; // Early accompanying member cost
  //   } else if (today.isBefore(lateDate)) {
  //     cost += 2000; // Late accompanying member cost
  //   } else {
  //     cost += 2500; // Spot accompanying member cost
  //   }
  // }
    setFormData({ ...formData, cost });
  };

  // const showAccompanyingMemberField = formData.packageName === 'Residential Twin Sharing';
  const showMembershipIDField = formData.membership === 'Member';
  const showLetterFromHODField = formData.membership === 'PG';
  const isPGMembership = formData.membership === 'PG';
  const isLateDate = today.isAfter(lateDate);

  const handleQRScannerClick = () => {
    setIsQRScannerOpen(true);
  };

  const closeQRScannerPopup = () => {
    setIsQRScannerOpen(false);
  };

  return (
    <div className="registration-form-container">
      <h2 className="registration-title">Event Registration</h2>
      <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
            Online Registrations closed. Spot Registration available.
      </p>
      {loading && (
        <div className="loader-container">
          <DNA
            visible={true}
            height="100"
            width="100"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            colors={['#0d243d', '#00afef']}
          />
        </div>
      )}

      {!loading && (
        <form className="registration-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input disabled
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="age">Age</label>
            <input disabled
              type="tel"
              id="age"
              name="age"
              value={formData.age}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender">Gender</label>
            <select disabled
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input disabled
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile Number</label>
            <input disabled
              type="tel"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="membership">Membership</label>
            <select disabled
              id="membership"
              name="membership"
              value={formData.membership}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Membership</option>
              <option value="Member">Nerosa Member</option>
              <option value="Non-member">Non-member</option>
              <option value="PG">PG</option>
            </select>
          </div>
          {showMembershipIDField && (
            <div className="form-group">
              <label htmlFor="membershipId">Membership ID</label>
              <input disabled
                type="text"
                id="membershipId"
                name="membershipId"
                value={formData.membershipId}
                onChange={handleInputChange}
                required
              />
            </div>
          )}
          
          {showLetterFromHODField && (
            <div className="form-group">
              <label htmlFor="letterFromHOD">Letter from HOD</label>
              <input disabled
                type="file"
                id="letterFromHOD"
                name="letterFromHOD"
                onChange={handleFileChange}
                required
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="packageName">Package</label>
            <select 
              id="packageName"
              name="packageName"
              value={formData.packageName}
              onChange={handleInputChange}
              required
              disabled={isPGMembership || isLateDate} // Disable package selection if PG is selected
            >
              <option value="">Select Package</option>
              {!isPGMembership && <option value="Residential Twin Sharing">Alternative Residential Package (Twin Sharing)</option>}
              {!isPGMembership && <option value="Residential Family">Alternative Residential Package (Family)</option>}
              <option value="Non-Residential">Non-Residential Package</option>
            </select>
          </div>
          {/* {showAccompanyingMemberField && (
            <div className="form-group">
              <label htmlFor="accompanyingMemberName">Accompanying Member Name</label>
              <input
                type="text"
                id="accompanyingMemberName"
                name="accompanyingMemberName"
                value={formData.accompanyingMemberName}
                onChange={handleInputChange}
              />
            </div>
          )} */}
          <table className="table responsive-table">
            <thead>
              <tr>
                <th>Non-Residential Package</th>
                <th>1st Jan 2025 - 15th Feb 2025</th>
                <th>Late (16th Feb 2025 - 6th March 2025)</th>
                <th>Spot</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nerosa Member</td>
                <td>₹3500</td>
                <td>₹4000</td>
                <td>₹4500</td>
              </tr>
              <tr>
                <td>Non-member</td>
                <td>₹4500</td>
                <td>₹5000</td>
                <td>₹5500</td>
              </tr>
              
              
              <tr>
                <td>PG</td>
                <td>₹2500</td>
                <td>₹3000</td>
                <td>₹3500</td>
              </tr>
            </tbody>
          </table>

          <table className="table responsive-table">
            <thead>
              <tr>
                <th>Residential Package</th>
                <th>Late (16th Feb 2025 - 27th Feb 2025)</th>
                <th style={{ color: "red"}}>Alternative Residential Package (27th Feb 2025 onwards)</th>
                <th>Spot</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nerosa Member (twin sharing)</td>
                <td>Sold out</td>
                <td style={{ color: "red"}}><b>₹12000</b></td>
                <td>NA</td>
              </tr>
              <tr>
                <td>Non-Member (twin sharing)</td>
                <td>Sold out</td>
                <td style={{ color: "red"}}><b>₹12000</b></td>
                <td>NA</td>
              </tr>
              <tr>
                <td>Family (2 people)</td>
                <td>Sold out</td>
                <td style={{ color: "red"}}><b>₹16000</b></td>
                <td>NA</td>
              </tr>
            </tbody>
          </table>
        
        <div className="form-group">
          <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
            Residential Packages have sold out. Alternative Residential Packages are available.
          </p>

          <p>
            <b>Residential Package (Twin Sharing):</b> Conference cost + 2-night hotel stay (twin sharing) at Lemon Tree Hotel, Gangtok.
          </p>
          <p>
            <b>Residential Package (Family):</b> Conference cost + 2-night hotel stay (2 people) at Lemon Tree Hotel, Gangtok.
          </p>
          <hr></hr>
          <ul>
            <li>Limited rooms available, on a first-come first-served basis.</li>
            <li>
              For an extra bed, please contact <a href="https://www.neoscon2025.in/venue">Lemon Tree Hotel</a>. Additional charges will apply as per hotel policy.
            </li>
          </ul>
          <hr></hr>
          <p>
            <b>Non-Residential Package:</b> Only conference cost.
          </p>
          <hr></hr>
          <p>
            <b>Alternative Residential Package (Twin Sharing):</b> Conference cost + 2-night hotel stay (twin sharing) at hotel Suhim Portico Heritage Sikkim.
          </p>
          <p>
            <b>Alternative Residential Package (Family):</b> Conference cost + 2-night hotel stay (2 people) at hotel Suhim Portico Heritage Sikkim.
            <p>
            Limited rooms available. For an extra bed, please contact <a href="https://www.neoscon2025.in/alt-venue">Suhim Portico Heritage Sikkim</a>. Additional charges will apply as per hotel policy.
            </p>
          </p>
          
          
        </div>
        <hr></hr>
        {/* <div className="form-group">
          <p>
            <b>Special Offer for our Senior Doctors</b>
          </p>
          <ul>
            <li>
              Senior doctors aged 65 years and above, conference registration fee is just ₹500/-. Please contact Dr. Shreel Koirala from the <a href="https://www.neoscon2025.in/contact-us">Registration committee</a>.
            </li>
          </ul>
        </div> */}

          {/* Additional form fields for costs and payment details */}
          {formData.cost > 0 && (
          <div className="form-group">
            {/* <hr></hr> */}
            <p><b>Your Total Cost:</b> ₹{formData.cost}/-</p>
            <hr></hr>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="paymentMethod">Select Payment Method</label>
          <select disabled
            id="paymentMethod"
            name="paymentMethod"
            value={paymentMethod}
            onChange={handlePaymentMethodChange}
            required
          >
            <option value="">Select Payment Method</option>
            <option value="qr">QR Scanner</option>
            <option value="bank">Bank Details</option>
          </select>
      </div>
      {paymentMethod === 'qr' && (
      <button type="button" className="qr-button" onClick={handleQRScannerClick}>Click here for QR Scanner</button>
        
    )}
          {isQRScannerOpen && (
          <div className="qr-popup">
            <div className="qr-popup-content">
              <button className="qr-popup-close" onClick={closeQRScannerPopup}>&times;</button>
              <h5>QR Scanner</h5>
              <img src={QRimg} alt="QR Code" className="qr-image" />
            </div>
          </div>
        )}

        {paymentMethod === 'bank' && (
  <div className="bank-details">
    <hr></hr>
    <p><b>Bank Account No:</b> 8676101009485</p>
    <p><b>Beneficiary Name:</b> SIKKIM ORTHOPAEDIC CHAPTER</p>
    <p><b>IFSC Code:</b> CNRB0008676</p>
    <p><b>Bank Name:</b> Canara Bank, Tadong Branch</p>
    <hr></hr>
  </div>
)}

          <div className="form-group">
          <label htmlFor="transactionIdMethod">Select Transaction ID Details</label>
          <select disabled
            id="transactionIdMethod"
            name="transactionIdMethod"
            value={transactionIdMethod}
            onChange={handleTransactionIdMethod}
            required
          >
            <option value="">Select Transaction ID Details</option>
            <option value="transactID">Transaction ID</option>
            <option value="transactScreenshot">Transaction ID Screenshot</option>
          </select>
      </div>

      {transactionIdMethod === 'transactID' && (
      
        <div className="form-group">
            <label htmlFor="transactionId">Transaction ID</label>
            <input disabled
              type="text"
              id="transactionId"
              name="transactionId"
              value={formData.transactionId}
              onChange={handleInputChange}
              required
            />
          </div>
    )}
{transactionIdMethod === 'transactScreenshot' && (
      
        <div className="form-group">
            <label htmlFor="screenshot">Upload Transaction Screenshot</label>
            <input disabled
              type="file"
              id="screenshot"
              name="screenshot"
              onChange={handleFileChange}
              required
            />
          </div>
    )}

          
          
          
          <button disabled type="submit" className="submit-button">Register</button>
        </form>
      )}
    </div>
  );
};

export default RegistrationForm;
