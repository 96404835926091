import React from "react";
import "./OurPartner.css";

// Importing images from assets folder
import partner1 from "../assets/images/partner1.png";
import partner2 from "../assets/images/partner2.png";
// import partner3 from "../assets/images/partner3.png";
// Add more images as needed

const partners = [partner1, partner2,]; // Store images in an array

const OurPartners = () => {
  return (
    <div className="partners-container">
      <div className="partners-grid">
        {partners.map((image, index) => (
          <img key={index} src={image} alt={`Partner ${index + 1}`} className="partner-image" />
        ))}
      </div>
    </div>
  );
};

export default OurPartners;
