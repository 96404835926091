import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomNavbar from './components/Navbar';
import Home from './components/Home';
import AbstractSubmission from './components/AbstractSubmission';
import RegistrationForm from './components/RegistrationForm';
import RegistrationSuccess from './components/RegistrationSuccess';
// import About from './components/About';
import Speakers from './components/Speakers';
import Footer from './components/Footer';
// import ContactUs from './components/ContactUs';
import PresidentMessage from './components/PresidentMessage';
import ScrollToTop from './components/ScrollToTop'; // Import the ScrollToTop component
import 'bootstrap/dist/css/bootstrap.min.css';
import Venue from './components/Venue';
import AlternateVenue from './components/AlternateVenue';
import Travel from './components/TravelPartnerPage';
import Gallery from './components/Gallery';
import OrganizingCommittee from './components/OrganizingCommittee';
import AbstractSuccessful from './components/AbstractSuccessful';
import OurPartner from './components/OurPartner';


function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Ensure the ScrollToTop component is added here */}
      <CustomNavbar />
      <Routes>
        <Route 
          path="/" 
          element={
            <>
              <Home />
              <PresidentMessage />
              <Gallery />
              <Speakers />
              <OrganizingCommittee />
              <Venue />
              <AlternateVenue />
              <Travel />
              <OurPartner />
            </>
          } 
        />
        <Route path="/abstract-submission" element={<AbstractSubmission />} />
        <Route path="/abstract-submission-success" element={<AbstractSuccessful />} />
        <Route path="/registration" element={<RegistrationForm />} />
        <Route path="/registration-success" element={<RegistrationSuccess />} />
        <Route path="/speakers" element={<Speakers />} />
        <Route path="/contact-us" element={<OrganizingCommittee />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/venue" element={<Venue />} />
        <Route path="/alt-venue" element={<AlternateVenue />} />
      </Routes>
      
      <Footer />
    </Router>
  );
}

export default App;
